.checkboxLabel {
  display: flex !important;
  flex-direction: row;
  min-height: 30px;
  margin: 10px 0 !important;
}

.checkboxInput {
  width: 380px !important;
  height: 20px;
}
.checkboxTitle {
  width: 210px;
}

.block {
  margin: 20px 0 50px;
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.blockTitle {
  display: flex;
  justify-content: space-between;
  background: transparent;
  margin: 10px 0;
}

.blockButton {
  appearance: none;
}

.nestedBlock {
  margin: 20px 0 50px;
  background: rgb(216, 227, 241);
  border-radius: 10px;
  padding: 20px;
}

.elementContainer {
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background: rgb(200, 200, 200);
}

.selectContainer {
  composes: elementContainer;
  padding: 10px 10px 0px 10px;
}

.inputBase {
  position: relative;
  display: flex;
  margin-bottom: 1.5rem;
}

.inputBaseLabel {
  order: 1;
  flex-basis: 210px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 10px;
}

.inputBaseContainer {
  order: 2;
  flex-grow: 1;
  position: relative;
}

.inputBaseInput {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0.5rem;
  margin: 0;
}

select.inputBaseInput {
  appearance: auto;
}

.validationMessage {
  color: red;
  flex-basis: 100%;
  padding-top: 0.1rem;
  font-size: 12px;
  position: absolute;
}

.baseButton {
  padding: 10px 20px;
  background-color: var(--default-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  margin: 10px 10px;
}

.baseButton:hover {
  background-color: #d32f2f;
}

.baseButton:disabled {
  background-color: gray;
}

.containerCenter {
  display: flex;
  justify-content: center;
}

.containerRight {
  display: flex;
  justify-content: right;
}
