@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  @extend .flexColumn;
  position: absolute;
  gap: 24px;
  margin-top: 40px;
  width: 780px;
}

.mainTitle {
  @extend .h1Font;
}

.newsContainer {
  @extend .flexColumn;
  gap: 16px;
}

.newsTitleContainer {
  @extend .flexColumn;
  gap: 8px;
}

.newsDate {
  display: flex;
  padding: 2px 8px;
  align-items: baseline;
  gap: 8px;
  border-radius: 4px;
  background: $gray00;
  @extend .bodyBoldFont;
}

.newsTitle {
  @extend .a2SemiboldFont;
  color: $blue40;
}

.contentContainer {
  @extend .flexColumn;
  gap: 16px;
}

.paragraph {
  @extend .bodyRegularFont;
}
