@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $gray20;
  width: 302px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 20px 0 rgba(0, 53, 255, 0.1);
  &.small {
    width: 182px;
    padding: 12px 16px;
  }
  &.onlyTitle {
    width: fit-content;
    max-width: 350px;
  }
}

.titlesContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  @extend .h2Font;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: $blue40;
  &.noColorTitle {
    color: $gray50;
  }
}

.subTitle {
  @extend .h3Font;
  word-wrap: break-word;
  &.subTitleBlue {
    color: $blue40;
  }
}

.description {
  @extend .bodyRegularFont;
  word-wrap: break-word;
  color: $gray40;
}

.paragraph {
  @extend .bodyRegularLongFont;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 0;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid $gray20;
  padding-top: 8px;
  @extend .bodySemiboldFont;
  color: $gray40;
}

.subscription {
  @extend .bodyRegularLongFont;
  word-wrap: break-word;
}

.subscriptionLink {
  @extend .bodyRegularLongFont;
  max-width: max-content;
  &:hover {
    color: $blue40;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  word-wrap: break-word;
}

.itemValue {
  text-align: right;
  font-weight: 700;
}

.square {
  margin-top: 2px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: inline-block;
}
