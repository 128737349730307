@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.infoBlock {
  text-align: left;
  padding: 10px 20px;
  height: 100%;
}

.image {
  width: 100%;
}

.nestedBlock {
  padding: 10px 0px;
}

.blocksWithNested {
  padding: 16px 20px;
  border-bottom: 1px solid $gray20;
  display: inline-block;
  width: 100%;
}

.blocksWithNestedTitleContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  user-select: none;
  cursor: pointer;
}

.blocksWithNestedTitle {
  @extend .h2Font;
  flex: 1 0 0;
  display: flex;
  gap: 8px;
}

.detailsIconDown {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.detailsIconUp {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url("../../icons/details-icon-up.svg");
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}

.nestedContent {
  overflow: hidden;
  transition: max-height;
}

.checkboxContainer {
  flex-grow: 0;
}
